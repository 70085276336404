import React from 'react';
import { graphql, Link, navigate } from 'gatsby';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import { repositoryConfigs } from '../utils/prismicPreview';

import Layout from '../components/Layout';
import SliceZone from '../components/SliceZone';
import LeftArrowIcon from '../assets/icons/left-arrow.svg';
import TwitterIcon from '../assets/icons/twitter-black-stroke.svg';
import LinkedinIcon from '../assets/icons/linkedin.svg';
import FacebookIcon from '../assets/icons/facebook-black-stroke.svg';

const BlogPage = ({ data }) => {
  if (!data) {
    return null;
  }

  const pageContent = data.prismicBlogDetails;
  const blog = pageContent.data || {};

  const { lang, type, url } = pageContent;
  const alternateLanguages = pageContent.alternate_languages || [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  };

  return (
    <Layout
      customSEO={{
        title: blog.title.text,
        description: blog.description,
        image: blog.banner.url
      }}
      activeDoc={activeDoc}
    >
      <div className="container w-11/12 sm:w-10/12 lg:w-full px-0 mx-auto mt-12 mb-28">
        <div className="lg:grid lg:grid-cols-12">
          <div className="col-span-1" >
            <LeftArrowIcon className="cursor-pointer" onClick={async () => navigate(-1)} />
          </div>
          <div className="col-span-11">
            <h5 className="font-extralight text-2xl text-d-gray-10 mt-5 lg:-mt-1 lg:w-1/2">
              {blog.title.text}
            </h5>
            <div className="lg:w-4/5">
              <div className="mt-2">
                <SliceZone slices={blog.body[0]} />
              </div>
              <div className="lg:flex lg:w-4/5 lg:mx-auto">
                <div className="flex lg:flex-col mt-5">
                  <Link to="#" className="social-icon">
                    <TwitterIcon className="stroke-current text-black" />
                  </Link>

                  <Link to="#" className="social-icon ml-4 lg:ml-0 lg:mt-5">
                    <LinkedinIcon />
                  </Link>

                  <Link to="#" className="social-icon ml-4 lg:ml-0  lg:mt-5">
                    <FacebookIcon />
                  </Link>
                </div>
                <div>
                  <div className="mt-6 lg:mt-4 lg:ml-16">
                    <span className="text-sm font-light text-d-gray-9">{blog.date}</span>
                    <div className="blog-text children:py-6 text-lg font-light text-d-gray-9">
                      <SliceZone slices={blog.body[1]} />
                    </div>
                    <div className="text-d-gray-9 mt-14 sm:relative">
                      <div className="w-3/5 sm:absolute sm:top-0 sm:right-0 sm:w-1/3">
                        <StaticImage src="../assets/images/static-blog-image.png" alt="riven   keyboard" />
                      </div>
                      <div className="sm:w-3/5">
                        <span className="text-sm font-light inline-block mt-5">16 Aug, 2021</span>
                        <h5 className="font-extralight text-2xl mt-1 mb-2 text-d-gray-10">News title</h5>
                        <p className="text-lg font-light">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Metus, malesuada etiam leo proin et tempus.v
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
query blogQuery($id: String, $lang: String) {
  prismicBlogDetails(id: { eq: $id }, lang: { eq: $lang }) {
    _previewable
    url
    type
    lang
    alternate_languages {
      id
      type
      lang
      uid
    }
    data {
      body {
        ...BlogDataBody
      }
      description
      title {
        ...TextContent
      }
      banner {
        gatsbyImageData(
          layout: FULL_WIDTH,
          height: 400
        )
        url
        alt
      }
      date(formatString: "MMMM DD, YYYY")
    }
  }
}
`;

export default withPrismicPreview(BlogPage, repositoryConfigs);
